.App {
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
}

header,
main {
  width: 100%;
  max-width: 960px; /* Adjust based on your preference */
}

nav ul {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  gap: 20px; /* Space between navigation items */
}

nav a {
  text-decoration: none;
  color: #000; /* Adjust color as needed */
}

article {
  margin-bottom: 20px;
}

/* Optionally style the language selector for better visual integration */
.LanguageSelector {
  margin-top: 20px;
}

/* Add more styles here as needed */
